<script setup lang="ts">
interface Props {
  className?: string;
  bannerImage?: URL;
}
const bannerPlaceholder = "/media/images/banner-placeholder.jpg";

defineProps<Props>();
</script>

<template>
  <div
    :class="`w-full flex bg-no-repeat ${className}`"
    :style="`background-image: url('${
      bannerImage?.href ? bannerImage.href : bannerPlaceholder
    }'); background-position: ${!bannerImage && 'center'}; background-size: ${
      !bannerImage && 'cover'
    }`"
  >
    <div class="flex flex-col w-full items-center justify-center">
      <slot />
    </div>
  </div>
</template>
